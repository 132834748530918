<template>
  <a-layout id="components-layout-demo-side" style="background: #f5f5f5;height:100%">
    <div v-if="loading" class="example"
         style="position:absolute;top: 0;right: 0;left: 0;bottom: 0;z-index: 9999;background: rgba(0,0,0,.05);text-align: center;display: flex;align-items: center;justify-content: center">
      <a-spin size="large"/>
    </div>
    <sider-bar :style="{ height: '100%', position: 'fixed', left: 0 }"/>
    <a-layout :style="sidebar.opened?{ marginLeft: '80px' }:{marginLeft: '208px'}">
      <!-- <layout-header style="position: sticky;top: 0;border-bottom: 1px solid #e8e8e8;" /> -->
      <a-layout-content>
        <div :style="{ minHeight: '360px'}" style="background: #f5f5f5">
          <router-view/>
        </div>
      </a-layout-content>
      <!--      <layout-footer :style="{ 'width': '100%', 'text-align': 'center' }" />-->
    </a-layout>
  </a-layout>
</template>
<script>
import SiderBar from '@/views/Layouts/SiderBar.vue'
// import LayoutHeader from '@/views/Layouts/LayoutHeader'
// import LayoutFooter from '@/views/Layouts/LayoutFooter'
import { mapGetters } from 'vuex'

export default {
  components: {
    SiderBar
    // LayoutHeader,
    // LayoutFooter
  },
  computed: {
    ...mapGetters(['sidebar', 'screenwidth', 'loading'])
  }
}
</script>
<style lang="scss">
.ant-layout-content {
  background: #f5f5f5;
}

.ant-layout-sider-collapsed {
  .logo {
    margin-left: 20px;
  }
}
</style>
