import Vue from 'vue'
import VueRouter from 'vue-router'
import Layout from '@/views/Layouts/Index'
import store from '../store'

Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push
//修改原型对象中的push方法
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch((err) => err)
}

const routes = [
  {
    path: '/',
    component: Layout,
    redirect: '/home',
    children: [
      {
        path: 'home',
        component: () => import('../views/Home'),
        name: 'Home',
        meta: {
          title: '首页',
          noCache: true
        }
      },
      {
        path: 'setting',
        component: () => import('../views/Setting'),
        name: 'setting',
        meta: {
          title: '设置',
          noCache: true
        }
      },
      {
        path: 'staff',
        component: () => import('../views/Setting/administrator/staff'),
        name: 'staff',
        meta: {
          title: '账号管理',
          ptitle: {
            title: '设置',
            path: 'setting'
          },
          noCache: true
        }
      },
      {
        path: 'train',
        component: () => import('../views/Train/index'),
        name: 'Train',
        meta: {
          title: '培训发证',
          noCache: true
        }
      },
      {
        path: 'three-level-safety-education',
        component: () => import('../views/Train/threeLevelSafetyEducation/index'),
        name: 'three-level-safety-education',
        meta: {
          title: '三级安全教育线上培训',
          ptitle: {
            title: '培训发证',
            path: 'train'
          },
          noCache: true
        }
      },
      {
        path: 'Recruitment',
        component: () => import('../views/Recruitment/index'),
        name: 'Recruitment',
        meta: {
          title: '招聘管理',
          noCache: true
        }
      },
      {
        path: 'postManagement/add',
        component: () => import('../views/Recruitment/postManagement/add'),
        name: 'postManagement-add',
        meta: {
          title: '发布岗位',
          ptitle: {
            title: '招聘管理',
            path: 'Recruitment'
          },
          noCache: true
        }
      },
      {
        path: 'postManagement/detail',
        component: () => import('../views/Recruitment/postManagement/detail'),
        name: 'postManagement-detail',
        meta: {
          title: '发布岗位',
          ptitle: {
            title: '招聘管理',
            path: 'Recruitment'
          },
          noCache: true
        }
      },
      {
        path: 'complain',
        component: () => import('../views/Complain/index'),
        name: 'Complain',
        meta: {
          title: '投诉',
          noCache: true
        }
      },
      {
        path: 'dispatch',
        component: () => import('../views/Dispatch/index'),
        name: 'Dispatch',
        meta: {
          title: '派单',
          noCache: true
        }
      },
      {
        path: 'blacklist',
        component: () => import('../views/Blacklist/index'),
        name: 'Blacklist',
        meta: {
          title: '黑名单',
          noCache: true
        }
      },
      {
        path: 'blacklist-upload',
        component: () => import('../views/Blacklist/upload'),
        name: 'BlacklistUpload',
        meta: {
          title: '黑名单上传',
          ptitle: {
            title: '黑名单',
            path: 'blacklist'
          },
          noCache: true
        }
      },
      {
        path: 'projectManage',
        component: () => import('../views/ProjectManage'),
        name: 'projectManage',
        meta: {
          title: '项目管理',
          noCache: true
        }
      },
      {
        path: 'worker',
        component: () => import('../views/ProjectManage/worker'),
        name: 'worker',
        meta: {
          title: '工人信息',
          ptitle: {
            title: '项目管理',
            path: 'projectManage'
          },
          noCache: true
        }
      },
      {
        path: 'construction',
        component: () => import('../views/Construction'),
        name: 'construction',
        meta: {
          title: '员工管理',
          noCache: true
        }
      },
      {
        path: 'employee',
        component: () => import('../views/Construction/employee'),
        name: 'employee',
        meta: {
          title: '员工列表',
          ptitle: {
            title: '员工管理',
            path: 'construction'
          },
          noCache: true
        }
      },
      {
        path: 'employee_info',
        component: () => import('../views/Construction/employee/employeeInfo'),
        name: 'employee_info',
        meta: {
          title: '员工列表',
          ptitle: {
            title: '员工管理',
            path: 'construction'
          },
          noCache: true
        }
      },
      {
        path: 'editTax',
        component: () =>
          import('../views/Construction/employee/employeeInfo/taxDeduction/edit.vue'),
        name: 'editTax',
        meta: {
          title: '员工列表',
          ptitle: {
            title: '员工管理',
            path: 'construction'
          },
          noCache: true
        }
      },
      {
        path: 'cpayroll',
        component: () => import('../views/Construction/payroll'),
        name: 'cpayroll',
        meta: {
          title: '工资表',
          ptitle: {
            title: '员工管理',
            path: 'construction'
          },
          noCache: true
        }
      },
      {
        path: 'upload',
        component: () => import('../views/Construction/payroll/upload'),
        name: 'upload',
        meta: {
          title: '上传工资表',
          ptitle: {
            title: '员工管理',
            path: 'construction'
          },
          noCache: true
        }
      },
      {
        path: 'employee-import',
        component: () => import('../views/Construction/employee-import/upload/index'),
        name: 'EmployeeImport',
        meta: {
          title: '上传员工表',
          ptitle: {
            title: '员工管理',
            path: 'construction'
          },
          noCache: true
        }
      },
      {
        path: 'id-card-batch',
        component: () => import('../views/Construction/id-card-batch/upload/index'),
        name: 'IdCardBatch',
        meta: {
          title: '上传身份证',
          ptitle: {
            title: '员工管理',
            path: 'construction'
          },
          noCache: true
        }
      },
      {
        path: 'salarytable',
        component: () => import('../views/Salarytable'),
        name: 'salarytable',
        meta: {
          title: '工资表',
          noCache: true
        }
      },
      {
        path: 'create',
        component: () => import('../views/Salarytable/create'),
        name: 'create',
        meta: {
          title: '一键制表',
          ptitle: {
            title: '工资表',
            path: 'salarytable'
          },
          noCache: true
        }
      },
      {
        path: 'create-preview',
        component: () => import('../views/Salarytable/create/preview'),
        name: 'createPreview',
        meta: {
          title: '工资单预览',
          ptitle: {
            title: '工资表',
            path: 'salarytable'
          },
          noCache: true
        }
      },
      {
        path: 'record',
        component: () => import('../views/Salarytable/record'),
        name: 'record',
        meta: {
          title: '制表记录',
          ptitle: {
            title: '工资表',
            path: 'salarytable'
          },
          noCache: true
        }
      },
      {
        path: 'record_detail',
        component: () => import('../views/Salarytable/record/detail.vue'),
        name: 'record_detail',
        meta: {
          title: '工资表详情',
          ptitle: {
            title: '工资表',
            path: 'salarytable'
          },
          noCache: true
        }
      },
      {
        path: 'comparison_detail',
        component: () => import('../views/Salarytable/record/comparison.vue'),
        name: 'comparison_detail',
        meta: {
          title: '比对详情',
          ptitle: {
            title: '比对',
            path: 'salarytable'
          },
          noCache: true
        }
      },
      {
        path: 'create_detail',
        component: () => import('../views/Salarytable/create/detail.vue'),
        name: 'create_detail',
        meta: {
          title: '上传工资表详情',
          ptitle: {
            title: '工资表',
            path: 'salarytable'
          },
          noCache: true
        }
      }
    ]
  },
  {
    path: '/official',
    component: () => import('../views/OfficialSite.vue'),
    name: 'Official',
    meta: {
      auth: false,
      title: '官网'
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: () => import('../views/Login.vue'),
    meta: {
      auth: false,
      title: '登录'
    }
  },
  {
    path: '/404',
    name: 'Page404',
    component: () => import('../views/404.vue'),
    meta: {
      auth: false,
      title: '404'
    }
  },
  {
    path: '*',
    redirect: '/404'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // if (to.meta.ptitle) {
  //   store.commit('SET_BREADCRUMBS', [{ title: '系统' }, { ...to.meta.ptitle, fullPath: from.fullPath }, { ...to.meta }])
  // } else {
  //   store.commit('SET_BREADCRUMBS', [{ title: '系统' }, { ...to.meta }])
  // }
  // if (!to.meta.ptitle) {
  //   store.commit('SET_BREADCRUMBS', [{ title: '系统' }, { ...to.meta }])
  // }
  let { auth = true } = to.meta
  let current = new Date().getTime()
  // let isLogin = Boolean(store.getters.expire > current && store.getters.token)
  let isLogin = Boolean(store.getters.token)
  if (isLogin) {
    next()
  } else {
    if (!auth) {
      // 未登录且为白名单
      next()
    } else {
      if (from.path == '/') {
        next('/official')
      } else {
        next('/login')
      }
    }
  }
})
router.afterEach(function (transition) {
  if (transition.meta.title) {
    document.title = transition.meta.title
  } else {
    document.title = '工地云'
  }
})

export default router
