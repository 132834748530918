import Vue from 'vue'
import Vuex from 'vuex'
import createPersisted from 'vuex-persistedstate'
import user from '@/store/modules/user'
import app from '@/store/modules/app'
import getters from './getters'
import 'babel-polyfill'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    user,
    app
  },
  plugins: [createPersisted({
    key: 'vuex'
  })],
  getters
})
