export const regCollection = {
  mobileReg: /^1[3456789]\d{9}$/,
  phoneReg: /^0\d{2,3}-?\d{7,8}$/,
  smsCodeReg: /^\d{6}$/,
  idCardReg: /(^[1-9]\d{5}(18|19|([23]\d))\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{3}[0-9Xx]$)|(^[1-9]\d{5}\d{2}((0[1-9])|(10|11|12))(([0-2][1-9])|10|20|30|31)\d{2}[0-9Xx]$)/,
  emailReg: /^[a-zA-Z0-9_.-]+@[a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)*\.[a-zA-Z0-9]{2,6}$/,
  numberReg: /^[0-9]*$/,
  bankNumReg: /^\d{16,19}$/,
  userNameReg: /^[0-9A-Za-z]{6,20}$/,
  decimalReg: /^[0-9]+\.{0,1}[0-9]{0,2}$/,
  kfTelReg: /^[+]{0,1}(\d){1,3}[ ]?([-]?((\d)|[ ]){1,12})+$/,
  webUrl: /^((ht|f)tps?):\/\/[\w\-]+(\.[\w\-]+)+([\w\-.,@?^=%&:\/~+#]*[\w\-@?^=%&\/~+#])?$/,
  pwdReg: /^(?![0-9]+$)(?![a-zA-Z]+$)[0-9A-Za-z]{8,}$/,
  specialCode: /[`~!#$%^&*()_\+=<>?:"{}|~！#￥%……&*（）={}|《》？：“”【】、；‘’，。、\s+]/,
  ipAddress: /^((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})(\.((2(5[0-5]|[0-4]\d))|[0-1]?\d{1,2})){3}$/
}

export const copy = (string, callback) => {
  const oInput = document.createElement('input')
  oInput.value = string
  document.body.appendChild(oInput)
  oInput.select()
  document.execCommand('Copy')
  oInput.className = 'oInput'
  oInput.style.display = 'none'
  callback && callback()
}

export function needToast() {
  const startDate = new Date(1589731200000)
  if (Date.now() > startDate.getTime()) {
    const date = new Date()
    return (date.getHours() === 23 && date.getMinutes() >= 45) || date.getHours() < 8
  } else {
    return false
  }
}

export const queryString = {
  parse(params) {
    if (typeof params !== 'string') {
      return {}
    }

    params = params.trim().replace(/^\?/, '')

    if (!params) {
      return {}
    }

    return params
      .trim()
      .split('&')
      .reduce(function (ret, param) {
        var parts = param.replace(/\+/g, ' ').split('=')
        var key = parts[0]
        var val = parts[1]

        key = decodeURIComponent(key)
        // missing `=` should be `null`:
        // http://w3.org/TR/2012/WD-url-20120524/#collect-url-parameters
        val = val === undefined ? null : decodeURIComponent(val)

        if (!ret.hasOwnProperty(key)) {
          ret[key] = val
        } else if (Array.isArray(ret[key])) {
          ret[key].push(val)
        } else {
          ret[key] = [ret[key], val]
        }

        return ret
      }, {})
  },
  stringify(params) {
    return params
      ? Object.keys(params)
        .map(function (key) {
          var val = params[key]

          if (Array.isArray(val)) {
            return val
              .map(function (val2) {
                return (
                  encodeURIComponent(key) + '=' + encodeURIComponent(val2)
                )
              })
              .join('&')
          }

          return encodeURIComponent(key) + '=' + encodeURIComponent(val)
        })
        .join('&')
      : ''
  },
  setParam(key, value) {
    const params = this.parse(window.location.search)
    params[key] = value
    const newParamsString = this.stringify(params)
    history.pushState({}, '', `${window.location.pathname}?${newParamsString}`)
  }
}

export function downloadExistFilePath(filePath, fileName) {
  const link = document.createElement('a')
  link.style.display = 'none'
  link.href = filePath
  link.target = '_blank'
  link.setAttribute('download', fileName || '文件')
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
