import Vue from 'vue'
import Antd from 'ant-design-vue/es'
import App from './App.vue'
import router from './router'
import store from './store'
import * as Sentry from '@sentry/vue'
import { Integrations } from '@sentry/tracing'
import VueAreaLinkage from 'vue-area-linkage'
import 'vue-area-linkage/dist/index.css'
import {
  filters
} from './utils/filters'
import 'ant-design-vue/dist/antd.less'
// import '@/style/reset.less'
import '@/style/common.less'
// import BaiduMap from 'vue-baidu-map'
// import * as echarts from 'echarts'

Vue.config.productionTip = false

const isProd = process.env.NODE_ENV === 'production'
if (!isProd) {
  Vue.config.devtools = true
}
// Vue.prototype.$echarts = echarts
// Vue.use(BaiduMap, {
//   ak: 'H1lGvU7YMYrj9DgN2QkciIVlj4eiAhTa'
// })
Vue.use(VueAreaLinkage)
Vue.use(Antd)
Object.keys(filters).forEach(key => {
  Vue.filter(key, filters[key])
})
Vue.prototype.$filter = filters

Sentry.init({
  Vue,
  dsn: 'https://6694c3805b714899a14ced1d06176a4d@sentry.gongmall.com/54',
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracingOrigins: ['gd-qa.gongmall.com', 'gd.gongmall.com', /^\//]
    })
  ]
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
