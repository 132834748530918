<template>
  <div id="app">
    <a-config-provider :locale="locale">
      <a-spin :spinning="loading">
        <router-view v-if="isRouterAlive"></router-view>
      </a-spin>
    </a-config-provider>
  </div>
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN'
import { getMenuElementByUserId } from '@/apis/login'
import { mapMutations, mapGetters } from 'vuex'

export default {
  name: 'app',
  provide() {
    return {
      reload: this.reload
    }
  },
  computed: {
    ...mapGetters(['elementstree'])
  },
  data() {
    return {
      locale: zhCN,
      loading: this.$store.state.app.loading,
      isRouterAlive: true
    }
  },
  methods: {
    ...mapMutations([
      'SET_ELEMENTS',
      'SET_ELEMENTSTREE'
    ]),
    reload() {
      this.isRouterAlive = false
      this.$nextTick(() => {
        this.isRouterAlive = true
      })
    }
  },
  watch: {
    $route(to, from) {
      if (from.path != '/login') {
        // 已有权限说明已经拿过权限，无需重复调用getMenuElementByUserId，所以添加this.$store.getters.elements.length === 0
        if (this.$store.state.user.token && this.$store.getters.elements.length === 0) {
          debugger
          let toPath = to.path
          let toPathP = to.meta.ptitle || ''
          // console.log(toPathP)
          let isHasToPath = false
          getMenuElementByUserId({}).then((res) => {
            if (res.code === 0) {
              const elements = {}
              for (let i = 0; i < res.data.elementResList.length; i++) {
                elements[res.data.elementResList[i].code] = true
              }
              this.SET_ELEMENTS(elements)
              let menuList = res.data.elementTree
              menuList.forEach(item => {
                if (item.path == toPath || item.path == ('/' + toPathP.path)) {
                  isHasToPath = true
                }
              })
              // console.log(to)
              if (menuList.length > this.elementstree.length) {
                isHasToPath = false
              }
              this.SET_ELEMENTSTREE(menuList)
              if (!isHasToPath) {
                this.reload()
                this.$router.push('/home')
              }
            } else {
              this.$router.push('/404')
            }
          })
        }
      }
    }
  }
}
</script>
<style lang="less">
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: left;
  color: #2c3e50;
}
</style>
