export const filters = {
  toCHNdate: (value) => {
    if (value) {
      let arr = value.split('-')
      let out = ''
      if (arr.length === 3) {
        out = `${arr[0]}年${arr[1]}月${arr[2]}日`
      } else if (arr.length === 2) {
        out = `${arr[0]}年${arr[1]}月`
      } else {
        out = `${arr[0]}年`
      }
      return out
    }
    return value
  },
  kiloReg: (str) => {
    // 正则千分符
    let num = str ? str.toFixed(2) : '0.00'
    return num.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
  },
  formatDate: (str, reg = 'yyyy-MM-dd hh:mm:ss') => {
    if (!str) return ''
    let dateObject
    if (str instanceof Date) {
      dateObject = str
    } else {
      const newStr = str.replace(/-/g, '/')
      dateObject = new Date(newStr)
      if (dateObject == 'Invalid Date') {
        dateObject = new Date(str)
      }
    }
    let year = dateObject.getFullYear()
    let month = dateObject.getMonth() + 1
    let date = dateObject.getDate()
    let hour = dateObject.getHours()
    let minutes = dateObject.getMinutes()
    let seconds = dateObject.getSeconds()
    return reg
      .replace('yyyy', year)
      .replace('MM', format(month))
      .replace('dd', format(date))
      .replace('hh', format(hour))
      .replace('mm', format(minutes))
      .replace('ss', format(seconds))
  },
  mobileHide: (mobile) => {
    // 手机号脱敏
    return mobile ? mobile.replace(/(\d{3})\d*(\d{4})/, '$1****$2') : ''
  },
  nameHide: (name) => {
    // 姓名脱敏
    let arr = name ? name.split('') : []
    arr.forEach((item, index) => {
      if (index > 0) {
        arr[index] = '*'
      }
    })
    return arr.join('')
  },
  idNumberHide: (idNumber) => {
    // 姓名脱敏
    return idNumber ? idNumber.replace(/(\d{3})\d*(\d{2})/, '$1*************$2') : ''
  },
  cardHide: (card) => {
    // 姓名脱敏
    return card ? card.replace(/(\d{4})\d*(\d{4})/, '$1******$2') : ''
  },
  convertCurrency: (currencyDigits) => {
    // Constants:
    let MAXIMUM_NUMBER = 99999999999.99
    // Predefine the radix characters and currency symbols for output:
    let CN_ZERO = '零'
    let CN_ONE = '壹'
    let CN_TWO = '贰'
    let CN_THREE = '叁'
    let CN_FOUR = '肆'
    let CN_FIVE = '伍'
    let CN_SIX = '陆'
    let CN_SEVEN = '柒'
    let CN_EIGHT = '捌'
    let CN_NINE = '玖'
    let CN_TEN = '拾'
    let CN_HUNDRED = '佰'
    let CN_THOUSAND = '仟'
    let CN_TEN_THOUSAND = '万'
    let CN_HUNDRED_MILLION = '亿'
    let CN_SYMBOL = '人民币'
    let CN_DOLLAR = '元'
    let CN_TEN_CENT = '角'
    let CN_CENT = '分'
    let CN_INTEGER = '整'

    // letiables:
    let integral // Represent integral part of digit number.
    let decimal // Represent decimal part of digit number.
    let outputCharacters // The output result.
    let parts
    let digits, radices, bigRadices, decimals
    let zeroCount
    let i, p, d
    let quotient, modulus

    // Validate input string:
    currencyDigits = currencyDigits + ''
    if (currencyDigits === '') {
      // alert("请输入小写金额！");
      return ''
    }
    if (currencyDigits.match(/[^,.\d]/) != null) {
      // alert("小写金额含有无效字符！");
      return ''
    }
    if (
      currencyDigits.match(
        /^((\d{1,3}(,\d{3})*(.((\d{3},)*\d{1,3}))?)|(\d+(.\d+)?))$/
      ) == null
    ) {
      // alert("小写金额的格式不正确！");
      return ''
    }

    // Normalize the format of input digits:
    currencyDigits = currencyDigits.replace(/,/g, '') // Remove comma delimiters.
    currencyDigits = currencyDigits.replace(/^0+/, '') // Trim zeros at the beginning.
    // Assert the number is not greater than the maximum number.
    if (Number(currencyDigits) > MAXIMUM_NUMBER) {
      // alert("金额过大，应小于1000亿元！");
      return ''
    }

    // Process the coversion from currency digits to characters:
    // Separate integral and decimal parts before processing coversion:
    parts = currencyDigits.split('.')
    if (parts.length > 1) {
      integral = parts[0]
      decimal = parts[1]
      // Cut down redundant decimal digits that are after the second.
      decimal = decimal.substr(0, 2)
    } else {
      integral = parts[0]
      decimal = ''
    }
    // Prepare the characters corresponding to the digits:
    digits = [
      CN_ZERO,
      CN_ONE,
      CN_TWO,
      CN_THREE,
      CN_FOUR,
      CN_FIVE,
      CN_SIX,
      CN_SEVEN,
      CN_EIGHT,
      CN_NINE
    ]
    radices = ['', CN_TEN, CN_HUNDRED, CN_THOUSAND]
    bigRadices = ['', CN_TEN_THOUSAND, CN_HUNDRED_MILLION]
    decimals = [CN_TEN_CENT, CN_CENT]
    // Start processing:
    outputCharacters = ''
    // Process integral part if it is larger than 0:
    if (Number(integral) > 0) {
      zeroCount = 0
      for (i = 0; i < integral.length; i++) {
        p = integral.length - i - 1
        d = +integral.substr(i, 1)
        quotient = p / 4
        modulus = p % 4
        if (d === 0) {
          zeroCount++
        } else {
          if (zeroCount > 0) {
            outputCharacters += digits[0]
          }
          zeroCount = 0
          outputCharacters += digits[Number(d)] + radices[modulus]
        }
        if (modulus === 0 && zeroCount < 4) {
          outputCharacters += bigRadices[quotient]
          zeroCount = 0
        }
      }
      outputCharacters += CN_DOLLAR
    }
    // Process decimal part if there is:
    if (decimal !== '') {
      for (i = 0; i < decimal.length; i++) {
        d = decimal.substr(i, 1)
        if (d !== 0) {
          outputCharacters += digits[Number(d)] + decimals[i]
        }
      }
    }
    // Confirm and return the final output string:
    if (outputCharacters === '') {
      outputCharacters = CN_ZERO + CN_DOLLAR
    }
    if (decimal === '') {
      outputCharacters += CN_INTEGER
    }
    outputCharacters = CN_SYMBOL + outputCharacters
    return outputCharacters
  }
}
function format(num) {
  return num >= 10 ? num : '0' + num
}
// 去重
export const unique = (arr, id) => {            
  for (var i = 0; i < arr.length; i++) {
      for (var j = i + 1; j < arr.length; j++) {
          if (arr[i][id] == arr[j][id]) { //第一个等同于第二个，splice方法删除第二个
              arr.splice(j, 1)
              j--
          }
      }
  }
  return arr
}
