const getters = {
  username: state => state.user.username,
  loading: state => state.app.loading,
  collapsed: state => state.app.collapsed,
  expire: state => state.user.expire,
  token: state => state.user.token,
  projectInfo: state => state.user.projectInfo,
  userInfo: state => state.user.userInfo,
  breadcrumbs: state => state.app.breadcrumbs,
  elements: state => state.user.elements,
  elementstree: state => state.user.elementstree,
  sidebar: state => state.app.sidebar,
  screenwidth: state => state.app.screenwidth,
  isReset: state => state.user.isReset
}

export default getters
