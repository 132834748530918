import axios from 'axios'
import store from '@/store'
import {
  notification
} from 'ant-design-vue/es'

// 创建axios实例
const service = axios.create({
  timeout: 500000 // 超时时间
})
const whiteList = [
  process.env.VUE_APP_BASE_URL + '/sendSmsCode',
  process.env.VUE_APP_BASE_URL + '/login'
]
// 请求拦截器
service.interceptors.request.use(
  (config) => {
    // 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
    // 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
    // const loginInfo = storage.local.getItem('loginInfo')
    // if (loginInfo) {
    //   let { userId, token } = loginInfo
    //   config.headers.Authorization = 'Bearer ' + token
    //   config.headers.userId = userId
    // }
    // if (store.getters.token && store.getters.expire > new Date()) {
    if (store.getters.token) {
      //headers.Authorization = 'Bearer ' + store.getters.token
      if (whiteList.indexOf(config.url) !== -1) {
        delete config.headers.Authorization
      } else {
        config.headers.Authorization = store.getters.token
      }
    }
    // 给所有的get方法请求参数中加上 _t=时间戳，防止get请求缓存的问题
    if (config.method === 'get') {
      if (!config.params) {
        config.params = {}
      }
      Object.assign(config.params, {
        _t: new Date().valueOf()
      })
    }
    return config
  },
  (error) => {
    store.commit('TOGGLE_LOADING', false)
    return Promise.error(error)
  }
)

// 响应拦截器
service.interceptors.response.use(
  (response) => {
    store.commit('TOGGLE_LOADING', false)
    if (response.status === 200) {
      return Promise.resolve(response.data)
    } else {
      return Promise.reject(response)
    }
  },
  (error) => {
    store.commit('TOGGLE_LOADING', false)
    // 服务器状态码不是200的情况
    if (error && error.response) {
      if (error.response.status === 401) {
        notification.error({
          message: '错误',
          description: '请求登录超时,请重新登录'
        })
        store.commit('TOGGLE_LOADING', false)
        store.commit('SET_PROJECTINFO', {})
        store.commit('SET_EXPIRE', '')
        store.commit('SET_TOKEN', '')
        store.commit('SET_USERNAME', '')
        store.commit('SET_USERID', '')
        store.commit('SET_BREADCRUMBS', [])
        localStorage.clear()
        location.href = '/login'
        return
      }
      switch (error.response.status) {
        case 400:
          error.message = '请求错误'
          break
        case 401:
          error.message = '登录超时,请重新登录'
          break
        case 403:
          error.message = '拒绝访问'
          break
        case 404:
          error.message = `请求地址出错: ${error.response.config.url}`
          break
        case 408:
          error.message = '请求超时'
          break
        case 500:
          if (error.response.data.msg) {
            error.message = error.response.data.msg
          } else {
            error.message = '网络开小差啦，请稍后重试'
          }
          break
        case 501:
          error.message = '服务未实现'
          break
        case 502:
          error.message = '网关错误'
          break
        case 503:
          error.message = '服务不可用'
          break
        case 504:
          error.message = '网关超时'
          break
        case 505:
          error.message = 'HTTP版本不受支持'
          break
        default:
          error.message = '请求未知错误'
          break
      }
      notification.error({
        message: '错误',
        description: error.message
      })
    } else {
      // storage.local.clear()
      localStorage.clear()
      notification.error({
        message: '错误',
        description: '请求服务错误'
      })
      store.commit('TOGGLE_LOADING', false)
      if (location.pathname !== '/login') {
        // location.href = '/login'
      }
    }
    return Promise.reject(error.response)
  }
)

export function get(url, params, headers, extraConfig = {}) {
  const {
    loading = true, showError = true
  } = extraConfig
  store.commit('TOGGLE_LOADING', loading)
  return new Promise((resolve, reject) => {
    service
      .request({
        url,
        method: 'GET',
        params,
        headers
      })
      .then((res) => {
        store.commit('TOGGLE_LOADING', false)
        if (res.code !== 0 && showError) {
          notification.error({
            title: '错误',
            message: res.msg
          })
        }
        resolve(res)
      })
      .catch((err) => {
        store.commit('TOGGLE_LOADING', false)
        reject(err)
      })
  })
}

export function post(url, data, headers, extraConfig = {}) {
  const {
    loading = true, showError = true
  } = extraConfig
  store.commit('TOGGLE_LOADING', loading)
  return new Promise((resolve, reject) => {
    service
      .request({
        url,
        method: 'POST',
        data,
        headers
      })
      .then((res) => {
        store.commit('TOGGLE_LOADING', false)
        if (res.code !== 0 && showError) {
          notification.error({
            title: '错误',
            message: res.msg
          })
        }
        resolve(res)
      })
      .catch((err) => {
        store.commit('TOGGLE_LOADING', false)
        reject(err)
      })
  })
}

export default {
  get,
  post
}
