<template>
  <a-layout-sider class="sidebar-container" theme="dark" :trigger="null" collapsible v-model="sidebar.opened"
                  style="z-index: 1000;" :width="208">
    <div class="logo">
      <img :src="logo" class="sidebar-logo" alt="logo">
      <!--      <h1 v-if="!sidebar.opened" class="sidebar-title">{{ title }} </h1>-->
    </div>
    <a-menu theme="dark" :selected-keys="activeIndex" mode="inline" :inline-collapsed="sidebar.opened">
      <a-menu-item v-for="menu in menuList" :key="menu.path" @click="openMenu(menu)">
        <div class="menu-item-wrapper">
          <div>
            <i :class="menu.icon" style="font-size: 20px;margin-right: 10px;vertical-align: middle"/>
            <span style="vertical-align: middle">{{ menu.name }}</span>
          </div>
          <a-icon type="caret-right"/>
        </div>
      </a-menu-item>
    </a-menu>
    <div class="userInfo">
      <a-popover placement="right">
        <template slot="content">
          <div class="pointer" @click="changePwd">修改密码</div>
          <a-divider style="margin: 8px 0"/>
          <div class="pointer" @click="logout">退出登录</div>
        </template>
        <template slot="title">
        </template>
        <div class="logOut">
          <div>
            <a-icon type="user" class="icon"/>
          </div>
          <div class="user_name">
            <div>{{ username }}</div>
            <div style="font-size: 12px">{{ userrole }}</div>
          </div>
        </div>
      </a-popover>
    </div>
    <a-modal title="修改密码" v-model="visible" :closable="!isReset" :maskClosable="false">
      <div class="hint_cont">温馨提示：新账号默认密码为手机号后六位</div>
      <a-form-model ref="form" style="margin-top:30px" :rules="rules" :model="form" labelAlign="right" :labelCol="{span:6}"
                    :wrapperCol="{span:18}">
        <a-form-model-item label="原密码：" prop="oldPassword">
          <a-input-password v-model="form.oldPassword" style="width: 80%;" placeholder="请输入原密码"/>
        </a-form-model-item>
        <a-form-model-item label="新密码：" prop="newPassword">
          <a-input-password v-model="form.newPassword" style="width: 80%;" placeholder="请输入字母+数字的密码，不小于8位"/>
        </a-form-model-item>
        <a-form-model-item label="确认新密码：" prop="repeatNewPwd">
          <a-input-password v-model="form.repeatNewPwd" style="width: 80%;" placeholder="请再次输入密码"/>
        </a-form-model-item>
      </a-form-model>
      <span slot="footer">
        <a-button v-if="!isReset" @click="cancelForm">取消</a-button>
        <a-button type="primary" @click="onSubmit">保存</a-button>
      </span>
    </a-modal>
  </a-layout-sider>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import { getProjectOwnerUser, updatePassword } from '@/apis/login'
import Logo from '@/assets/logo.png'
import store from '@/store'
import { regCollection } from '../../utils'

export default {
  data() {
    return {
      activeIndex: [],
      userrole: '',
      placement: 'rightTop',
      visible: false,
      // list: [],
      // listCopy: [],
      menuList: [],
      title: '工地云',
      logo: Logo,
      form: {},
      rules: {
        oldPassword: [{
          required: true, message: '请输入原密码', trigger: 'blur'
        }],
        newPassword: [{
          required: true, message: '请输入新密码', trigger: 'blur'
        }, {
          validator: (rule, value, callback) => {
            regCollection.pwdReg.test(value) ? callback() 
            : callback(new Error('密码格式错误，提示：字母+数字不少于八位'))
          },
          trigger: ['blur']
        }],
        repeatNewPwd: [{
          required: true, message: '请重复新密码', trigger: 'blur'
        }, {
          validator: (rule, value, callback) => {
            if (this.form.newPassword === value) {
              callback()
            } else if (value || value !== '') {
              callback(new Error('两次密码不一致'))
            }
          },
          trigger: ['blur']
        }]
      }
    }
  },
  computed: {
    ...mapGetters(['collapsed', 'sidebar', 'username', 'isReset', 'projectInfo', 'userInfo', 'elementstree'])
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        if (to.path) {
          let path = to.path
          // console.log(to)
          if (to.meta.ptitle) {
            path = '/' + to.meta.ptitle.path
          }
          this.activeIndex = [path]
        }
      }
    }
  },
  methods: {
    ...mapMutations(['TOGGLE_COLLAPSED', 'SET_PROJECTINFO', 'SET_SIDEBAR_WIDTH', 'TOGGLE_SIDEBAR']),
    openMenu(item) {
      if (item.code === 'construction') {
        this.getProjectOwnerUser()
      } else {
        this.$router.push(item.url)
      }
    },
    getProjectOwnerUser() {
      getProjectOwnerUser({ type: 2, projectStatus: 1 }).then((res) => {
        if (res.code === 0) {
          if (!this.projectInfo.id || !this.isHaveProjectInfoId(res.data, this.projectInfo.id)) {
            this.SET_PROJECTINFO(res.data[0] || {})
          }
          this.$router.push({
            path: '/construction'
          })
        }
      })
    },
    isHaveProjectInfoId(data, id) {
      let flag = false
      data.forEach(item => {
        if (id == item.id) {
          flag = true
        }
      })
      return flag
    },
    // 退出登录和修改密码操作
    logout() {
      this.$confirm({
        title: '提示信息',
        content: '确定退出登录吗？',
        icon: 'warning',
        okText: '确认',
        cancelText: '取消',
        onOk: () => {
          store.commit('TOGGLE_LOADING', false)
          store.commit('SET_PROJECTINFO', {})
          store.commit('SET_EXPIRE', '')
          store.commit('SET_TOKEN', '')
          store.commit('SET_USERNAME', '')
          // store.commit('SET_USERID', '')
          store.commit('SET_BREADCRUMBS', [])
          store.commit('SET_ELEMENTS', {})
          store.commit('SET_ELEMENTSTREE', [])
          store.commit('SET_ISRESET', 0)
          localStorage.clear()
          // location.href = '/login'
          this.$router.push({
            path: '/login'
          })
        },
        onCancel() {
        }
      })
    },
    changePwd() {
      this.visible = true
    },
    cancelForm() {
      this.$refs['form'].resetFields()
      this.visible = false
    },
    onSubmit() {
      this.$refs.form.validate(valid => {
        if (valid) {
          const { oldPassword, newPassword } = this.form
          updatePassword({ oldPassword, newPassword }).then(res => {
            if (res.code === 0) {
              store.commit('TOGGLE_LOADING', false)
              store.commit('SET_PROJECTINFO', {})
              store.commit('SET_EXPIRE', '')
              store.commit('SET_TOKEN', '')
              store.commit('SET_USERNAME', '')
              // store.commit('SET_USERID', '')
              store.commit('SET_BREADCRUMBS', [])
              store.commit('SET_ELEMENTS', {})
              store.commit('SET_ELEMENTSTREE', [])
              store.commit('SET_ISRESET', 0)
              localStorage.clear()
              // location.href = '/login'
              this.$router.push({
                path: '/login'
              })
            }
          })
        }
      })
    }
  },
  mounted() {
    if (this.isReset) {
      this.visible = true
    }
    this.menuList = this.elementstree || []
    let userInfo = this.userInfo
    if (userInfo.isSuperManager) {
      this.userrole = '超级管理员'
    } else {
      this.userrole = userInfo.userRoleResList && userInfo.userRoleResList[0] ? userInfo.userRoleResList[0].roleName : '-'
    }
    this.TOGGLE_COLLAPSED(false)
  }

}
</script>
<style lang="less">
@import "../../style/variables";

.sidebar-container {
  padding-bottom: 72px;

  .logo {
    padding: 28px 0;
    text-align: center;
  }

  .userInfo {
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 16px 20px;
    z-index: 999;
    background:#2A2F3B;
    border-top: 1px solid rgba(7, 193, 96, .4);

    .logOut {
      cursor: pointer;
      display: flex;
      align-items: center;

      .icon {
        width: 40px;
        height: 40px;
        line-height: 40px;
        background-color: rgba(255, 255, 255, .5);
        margin-right: 12px;
        border-radius: 50%;
      }

      .user_name {
        color: #A2A2A2;
      }
    }
  }

  .ant-menu-item {
    border-left: 2px solid transparent;

    &.ant-menu-item-selected {
      border-left: 2px solid @primary-color;

      .iconfont {
        color: #fff;
      }
    }

    .menu-item-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;

      .anticon-caret-right {
        font-size: 8px;
      }
    }
  }
 
}
.hint_cont{
  position: absolute;
  left: 0;
  top: 60px;
  text-align: center;
  width: 100%;
  line-height: 40px;
  height: 40px;
  background: #fff0c5;
  box-sizing: border-box;
  box-shadow: 2px 0px 6px 0px rgba(0, 0, 0, 0.02);
  letter-spacing: 1px;
}
</style>
