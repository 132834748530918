const user = {
  state: {
    username: '',
    expire: '',
    token: '',
    projectInfo: {},
    userInfo: {},
    elements: {},
    elementstree: [],
    isReset: 0
  },
  mutations: {
    SET_USERNAME: (state, username) => {
      state.username = username
    },
    SET_TOKEN: (state, token) => {
      state.token = token
    },
    SET_EXPIRE: (state, expire) => {
      state.expire = expire
    },
    SET_PROJECTINFO: (state, projectInfo) => {
      state.projectInfo = projectInfo
    },
    SET_USERINFO: (state, userInfo) => {
      state.userInfo = userInfo
    },
    SET_ELEMENTS: (state, elements) => {
      state.elements = elements
    },
    SET_ELEMENTSTREE: (state, elementstree) => {
      state.elementstree = elementstree
    },
    SET_ISRESET: (state, isReset) => {
      state.isReset = isReset
    }
  },
  actions: {
    Login({
      commit
    }, userInfo) {
      return new Promise((resolve, reject) => {

      })
    }
  }
}
export default user
