import request from '@/utils/request'

const base = process.env.VUE_APP_BASE_URL + '/api'
const headers = {
  Accept: 'application/json',
  'Content-Type': 'application/json'
}

export const login = params => {
  let url = `${process.env.VUE_APP_BASE_URL}/login`
  return request.post(url, JSON.stringify(params), headers)
}

export const forgetPassword = params => {
  let url = `${process.env.VUE_APP_BASE_URL}/forgetPassword`
  return request.post(url, JSON.stringify(params), headers)
}

export const sendSmsCode = params => {
  let url = `${process.env.VUE_APP_BASE_URL}/sendSmsCode`
  return request.post(url, JSON.stringify(params), headers)
}

export const getProjectOwnerUser = params => {
  let url = `${base}/projectOrg/getProjectOwnerUser`
  return request.post(url, JSON.stringify(params), headers)
}

export const getMenuElementByUserId = params => {
  let url = `${base}/element/getMenuElementByUserId`
  return request.post(url, JSON.stringify(params), headers)
}

// 获取小程序码参数
export const getQrCOdeParam = params => {
  let url = `${process.env.VUE_APP_BASE_URL}/getQrCOdeParam`
  return request.get(url, params, headers)
}

// 修改密码
export const updatePassword = params => {
  let url = `${base}/user/update_password`
  return request.post(url, JSON.stringify(params), headers)
}

// 重置他人密码
export const resetPassword = params => {
  let url = `${base}/user/reset_password`
  return request.post(url, JSON.stringify(params), headers)
}

//  查找公司
export const companySearch = params => {
  let url = `${process.env.VUE_APP_BASE_URL}/company_search`
  return request.get(url, params, headers)
}
//  注册
export const register = params => {
  let url = `${process.env.VUE_APP_BASE_URL}/register`
  return request.post(url, JSON.stringify(params), headers)
}
