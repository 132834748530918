const screenW = document.body.clientWidth || document.documentElement.clientWidth
const app = {
  state: {
    loading: false,
    collapsed: false,
    breadcrumbs: [],
    sidebar: {
      opened: false,
      width: screenW > 768 ? 156 : 0
    },
    screenwidth: screenW
  },
  mutations: {
    TOGGLE_LOADING: (state, flag) => {
      state.loading = flag
    },
    TOGGLE_COLLAPSED: (state, flag) => {
      state.collapsed = flag
    },
    SET_BREADCRUMBS: (state, breadcrumbs) => {
      state.breadcrumbs = breadcrumbs
    },
    TOGGLE_SIDEBAR: (state, flag) => {
      if (flag == undefined) {
        state.sidebar.opened = !state.sidebar.opened
      } else {
        state.sidebar.opened = flag
      }
    },
    SET_SIDEBAR_WIDTH: (state, width) => {
      state.sidebar.width = width
    },
    SET_SCREEN_WIDTH: (state, width) => {
      state.screenwidth = width
    }
  },
  actions: {
    Login({
      commit
    }, userInfo) {
      return new Promise((resolve, reject) => {

      })
    },
    ToggleSidebar: ({ commit }) => {
      commit('TOGGLE_SIDEBAR')
    }
  }
}
export default app
